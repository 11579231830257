import logo from './logo.svg';
import './App.css';

function App() {
  return (
    <div className="container-fluid h-100 d-flex justify-content-center align-items-center">
        <div>Te amo ♡</div>
    </div>
  );
}

export default App;
